<template>
  <div class="sageInfo">
    <el-table :data="data" border :header-cell-style="HEADER_CELL_STYLE" :cell-style="SMALL_CELL_STYLE" width="50%">
      <el-table-column label="Status" width="120px">
        <template v-slot="scope">
          {{scope.row.success ? 'Successful' : 'Unsuccessful'}}
        </template>
      </el-table-column>
      <el-table-column label="Error Message" min-width="240px" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.errorMessage ? scope.row.errorMessage : '-'}}
        </template>
      </el-table-column>
      <el-table-column label="Date and Time" width="180px" show-overflow-tooltip>
        <template v-slot="scope">
          {{scope.row.updatedAt ? $moment(scope.row.updatedAt).format("YYYY-MM-DD HH:mm:ss") : '-'}}
        </template>
      </el-table-column>
      <el-table-column label="Customer Number" prop="customerNumber" width="180px" show-overflow-tooltip />
      <el-table-column lable="Order Number" prop="orderNumber" width="180px" show-overflow-tooltip />
      <el-table-column label="Ship To Location Key" prop="shipToLocationKey" width="180px" show-overflow-tooltip />
    </el-table>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE, SMALL_CELL_STYLE } from '@/constants.js'
import { getSageTrackOrderList } from '@/services/modules/ops'
export default {
  name: 'SageInformation',
  props: {
    jobId: {
      type: String,
      default: 0,
    },
  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      SMALL_CELL_STYLE,
      data:[],
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    getTableList() {
      getSageTrackOrderList(this.jobId).then(res => {
        if (res?.code === 1000) {
          this.data = res?.data ?? []
          this.checkSuccess()
        }
      })
    },
    checkSuccess() {
      let hasSuccess = false
      this.data.map(item => {
        if(item?.success) {
          hasSuccess = true
        }
      })
      this.$emit('hasSuccess', hasSuccess)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table__row {
  height: 50px;
}
</style>