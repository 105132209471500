<template>
	<el-select
    :filter-method="(val) => handleSearchMpaReceivingVessels(val)"
    filterable
    popper-class="custom-ship-selector"
    v-model="vesselSearchKey"
    style="width: 100%"
    :disabled="disabled"
    :loading="searchLoading"
    @visible-change="searchVesselvisibleChange"
    @change="$emit('handleSelectVessel',$event)"
    placeholder="Select"
  >
    <div class="header" >
      <span >Name</span>
      <span >IMO</span>
      <span >Licence</span>
    </div>
    <el-option  v-for="item in options" :key="item.id" :label="item.shipName" :value="item.id" >
      <div class="body">
        <span>{{ item.shipName }} </span>
        <span>{{ (item||{}).imo }}</span>
        <span>{{ (item||{}).licenceNo }}</span>
      </div>
    </el-option>
  </el-select>
</template>

<script>
import {   mapState } from "vuex";
import { getReceivingVesselListBySearch, } from "@/services/modules/comm.js";
export default {
  name: 'SearchVesselSelector',
  props: {
    disabled:Boolean,
    searchedVesselList:Array,
    cashType:String,
    schedulFormData:Object,
  },
  data () {
    return {
      vesselSearchKey:'',
      searchLoading:true,
      options:[]
    }
  },

  computed: {
    ...mapState({
      currentCompanySettings: "currentCompanySettings",
    }),

  },
  methods: {
    searchVesselvisibleChange(isVisit) {
      if (isVisit) {
        this.handleSearchMpaReceivingVessels("");
      }
    },
    async handleSearchMpaReceivingVessels(searchKey) {
      const companyId =
        this.currentCompanySettings.canFilterSearchByCompanyId &&
        this.cashType == "non-cash"
          ? this.schedulFormData.buyerSysOrganizationId
          : null;
      try {
        const res = await getReceivingVesselListBySearch(searchKey, companyId);
        if (res?.code === 1000) {
          this.options = res.data.records?.length > 0 ? res.data.records : [];
          this.$emit('update:searchedVesselList',this.options)
          this.searchLoading =false
        }
      } catch (error) {}
    },


  }
}
</script>
<style lang="scss" scoped>
.header, .body {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 2fr 1fr 1fr
}
.header{
  padding:10px;
  span{
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
 }
.body{
    span{
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
    }
 }

@media screen and (max-width: 599px) {
  .header{
    span{
      font-size: 12px;
    }
  }
.body{
    span{
      font-size: 11px;
    }
 }

}

</style>
